:global {
  .message-bottom-anchor {
    position: absolute;
    right: 34px;
    top: -60px;
    display: block;
    animation: fadeInFromNone 0.2s;
  }

  .message-bottom-anchor.hidden {
    animation: fadeOutFromNone 0.2s;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeOutFromNone {
    0% {
      opacity: 1;
      height: 32px;
    }
    99% {
      height: 32px;
    }
    100% {
      opacity: 0;
      height: 0;
    }
  }
}
