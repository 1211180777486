// Colors
$colors: (
  black: #090914,
  blue-light: #3d66ce,
  blue: #1b55e3,
  copy: #8a998e,
  gray-1: #f2f4f8,
  gray-2: #e4e9f0,
  gray-3: #dde2ec,
  gray-4: #c3bedc,
  gray-5: #aca6cc,
  gray-6: #786fa4,
  gray-7: #454d46,
  gray-8: #dfe6e0,
  gray-9: #2e332f,
  gray-10: #333,
  gray-11: #ebebeb,
  gray-12: #f5f5f5,
  gray-13: #ededed,
  gray-14: #424242,
  gray-15: #d6d6d6,
  gray-16: #fcfcfc,
  green-2: #0dcd79,
  green-3: #e6fff0,
  green-4: #94ca9c,
  green: #37e18d,
  orange-2: #f5a623,
  orange: #f2a654,
  pink: #fd3995,
  purple: #652eff,
  red-2: #e64545,
  red: #f00,
  white: #fff,
  yellow: #ff0,
);

// START - DEPRECATED! use the colors from the map $colors, these colors are here temporally for retrocompatibility
$black: #090914;
$blue-light: #3d66ce;
$blue: #1b55e3;
$copy: #8a998e;
$gray-1: #f2f4f8;
$gray-2: #e4e9f0;
$gray-3: #dde2ec;
$gray-4: #c3bedc;
$gray-5: #aca6cc;
$gray-6: #786fa4;
$gray-7: #454d46;
$gray-8: #dfe6e0;
$gray-9: #2e332f;
$gray-10: #333;
$gray-11: #ebebeb;
$gray-12: #f5f5f5;
$gray-13: #ededed;
$gray-14: #424242;
$gray-15: #d6d6d6;
$green-2: #0dcd79;
$green-3: #f7fcf8;
$green-4: #94ca9c;
$green: #37e18d;
$orange-2: #f5a623;
$orange: #f2a654;
$pink: #fd3995;
$purple: #652eff;
$red-2: #e64545;
$red: #f00;
$white: #fff;
$yellow: #ff0;
// END - DEPRECATED COLORS

$text: map-get($colors, 'copy');
$border: map-get($colors, 'gray-2');

// Accent colors
$default: map-get($colors, 'gray-4');
$primary: map-get($colors, 'green');
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #f39834;
$danger: #fb434a;
$light: map-get($colors, 'gray-1');
$dark: map-get($colors, 'black');

// Font Family
$base-font-family: 'ProximaNova-Regular', sans-serif;

// Font Size
$base-font-size: 15 !default;

// Font Family Types
@mixin primaryFont($weight: regular) {
  @if $weight == 'light' {
    font-weight: 300;
  } @else if $weight == 'medium' {
    font-weight: 500;
  } @else if $weight == 'semi-bold' {
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-weight: normal !important;
  } @else if $weight == 'bold' {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-weight: normal;
  } @else if $weight == 'extra-bold' {
    font-weight: 800;
  } @else if $weight == 'black' {
    font-weight: 900;
  } @else {
    font-family: $base-font-family;
    font-weight: 400;
  }
}

// Shadows
$shadow: 0 0 40px -10px rgba(#000, 0.2);
$shadow-2: 0 4px 38px 0 rgba(#000, 0.11), 0 0 21px 0 rgba(#000, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba(#000, 0.03), 0 0 10px 0 rgba(#000, 0.02);
$shadow-5: 0 0 40px -10px rgba(#000, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 544px;
$xs-max-width: 543px;
