:global {
  .ant-menu-vertical .ant-menu-item:first-child,
  .ant-menu-vertical-left .ant-menu-item:first-child,
  .ant-menu-vertical-right .ant-menu-item:first-child,
  .ant-menu-inline .ant-menu-item:first-child {
    margin-top: 0;
  }

  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 10px;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
    margin: 10px 0 0 -5px;
    font-size: 28px;
  }

  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: map-get($colors, 'green-3') !important;
  }

  .ant-menu-item-group-title {
    font-size: 14px;
    text-transform: uppercase;
  }
}
