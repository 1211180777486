:global {
  .ant-modal-title {
    font-weight: 700;
    text-transform: uppercase;
  }

  .ant-modal-content {
    border: 1px solid #ccc;
  }

  .ant-modal-header {
    background: #0dcd7915;
  }
}
