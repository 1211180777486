:global {
  body .carousel .control-dots .dot {
    background: $green;
    box-shadow: none;
  }

  body .carousel-root .carousel-status {
    color: $green;
    text-shadow: none;
  }
}
