@import 'assets/styles/mixins.scss';

@import './components/message-bottom-anchor.module.scss';
@import './components/carousel-overrides.module.scss';
@import './components/antd-tabs.module.scss';
@import './components/antd-modal.module.scss';
@import './components/antd-menu.module.scss';

:global {
  .chat-item {
    &.active-chat-item {
      background-color: #e6fff0;
      box-shadow: #0000003d 0 0 5px;
      position: relative;
      border-left: 5px solid rgba($green, 0.75);
    }

    &:hover {
      background-color: #e6fff0;
    }
  }

  .login-button {
    width: 100%;
  }

  .ant-layout-sider-children .anticon.anticon-notification.ant-menu-item-icon {
    transform: rotate(-15deg);
  }

  .toggle-menu-icon {
    color: #fff;
    font-size: 28px;
    margin: 8px;
    flex: 0 0 30px !important;
  }

  .configuration-layout {
    height: 100%;

    & .ant-layout-sider {
      background: #fff;
    }
  }

  .ant-card.ant-card-bordered.ant-card-hoverable.active {
    box-shadow: 0 1px 2px -2px fade($green, 20%), 0 3px 6px 0 fade($green, 20%),
      0 5px 12px 4px fade($green, 20%);
    border: 2px solid rgb(13, 205, 121 / 20%);
  }

  .ant-list-split .ant-list-item {
    border-bottom: 1px solid #dadada !important;
  }

  .ant-list-item-action {
    margin-left: 10px;
  }

  .ant-list-item.chat-item {
    padding: 5px 8px;
  }

  .ant-list-item-meta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-avatar-group {
    margin-bottom: 10px;
  }

  .chat-tags .ant-tag {
    padding: 0 4px;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .chat-workspace-name {
    padding: 0 4px;
    font-size: 11px;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0 !important;
  }
  .ant-dropdown-menu-item-group-title {
    padding-bottom: 0 !important;
  }
  .ant-form-item-explain-error {
    font-size: 10px;
  }
  .ant-btn-link {
    color: $green-2;
  }
  .ant-btn-link:not(:disabled):hover {
    color: $green-2;
  }
}

:root {
  @each $name, $color in $colors {
    --yoda-color-#{$name}: #{$color};
  }
}

:export {
  @each $name, $color in $colors {
    yoda-color-#{$name}: #{$color};
  }
}
